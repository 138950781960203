import React, {useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";
import { Button, Flag, List, Image, Container } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import cookies from 'js-cookie'

import MenuBar from "./MenuBar";

import AOS from 'aos'
import "aos/dist/aos.css"

const HowItWorks = () => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'

    const [deviceMobile, setDeviceMobile] = useState(false);

    const langOptions = [
        {
          key: 'English',
          text: 'EN',
          code: 'en',
          image: <Flag name='gb' />
        },
        {
          key: 'Deutsch',
          text: 'DE',
          code: 'de',
          image: <Flag name='de' />
        }
      ]

    useEffect(() => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
            )
        ) {
            setDeviceMobile(true);
        } else {
            setDeviceMobile(false);
        }
        AOS.init({
            disable: "phone",
            duration: 5000,
            easing: "ease-out-cubic",
        })
    }, []);

    const [activeItem, setActiveItem] = useState('how_it_works')

    let navigate = useNavigate(); 
    const routeChange = (x) =>{ 
      let path = `${x}`; 
      navigate(path);
    }

    return (
        <>
            <div className="App">

                <MenuBar active={'how_it_works'}/>

                <div data-aos="fade-right">
                <br />

                {deviceMobile ?
                    <Image src={t('how_it_works_illustration_mobile')} size='massive' centered/>
                    :
                    <Image src={t('how_it_works_illustration')} size='huge' centered/>
                }
                </div>

                <div data-aos="fade-right">
                <Container text>
                    <p>
                    {t('how_it_works_paragraph_1')}
                    </p>
                    <p>
                    {t('how_it_works_paragraph_2')}
                    </p>
                    <p>
                    {t('how_it_works_paragraph_3')}
                    </p>
                    <p>
                    {t('how_it_works_paragraph_4')}
                    </p>
                    <p>
                    {t('how_it_works_paragraph_5')}
                    </p>
                </Container>
                </div>
            </div>

            {/*Footer*/}

                <div className="Container">
                <List centered bulleted horizontal link size='large'>
                    <List.Item as='a' onClick={() => navigate('/terms')}>
                    {t('terms')}
                    </List.Item>
                    <List.Item as='a' onClick={() => navigate('/data_protection')}>
                    {t('data_protection')}
                    </List.Item>
                    <List.Item as='a' onClick={() => navigate('/imprint')}>
                    {t('imprint')}
                    </List.Item>
                </List>
                <div className="Container_middle">
                    <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
                </div>
                </div>
        </>
    )
}

export default HowItWorks;