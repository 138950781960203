import { Carousel } from 'react-carousel-minimal'
import React from 'react'

const ImageSliderPremium = () => {
    const CarouselData = [
        {
            image: "/images/balsamico-olive_premium.jpg",
        },
        {
            image: "/images/parker_premium.jpg",
        },
        {
            image: "/images/coffee_premium.jpg",
        },
        {
            image: "/images/chocolate_premium.jpg",
        },
    ]

    return(    <Carousel
                    data={CarouselData}
                    time={4000}
                    width="400px"
                    height="400px"
                    radius="10px"
                    slideNumber={false}
                    automatic={true}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={false}
                    style={{
                    textAlign: "center",
                    maxWidth: "1000px",
                    maxHeight: "500px",
                    margin: "40px auto",
                    }}
                />
    )
}

export default ImageSliderPremium