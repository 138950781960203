import React from 'react';
//import ReactDOM from 'react-dom/client';
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import './index.css';
import RedeemCode from './App';
import BuyCode from './BuyCode';
import Imprint from './Imprint';
import Terms from './Terms';
import Copyright from './Copyright';
import DataProtection from './DataProtection';
import HowItWorks from './HowItWorks';
import AutomaticCodeRedeem from './AutomaticCodeRedeem';
import BuyerInfo from './BuyerInfo';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'de'],
    fallbackLng: "en",
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
  });

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<BuyCode />} />
        <Route path='/order/:code/:name?' element={<BuyerInfo />} /> 
        <Route path='/:code' element={<AutomaticCodeRedeem />} />
        <Route path='/redeem' element={<RedeemCode />} />
        <Route path='/how_it_works' element={<HowItWorks />} />
        <Route path='/imprint' element={<Imprint />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/copyright' element={<Copyright />} />
        <Route path='/data_protection' element={<DataProtection />} />
      </Routes>
    </BrowserRouter>
  )
}

const root = document.getElementById("root");
render(<App />, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
