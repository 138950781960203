import './App.css'
import React, { useState, useEffect, useRef } from "react"
import { Button, Menu, Dropdown, Flag, Icon, Header, Image, List, Grid } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import i18next, { use } from 'i18next'
import cookies from 'js-cookie'
import { useNavigate } from "react-router-dom"

import Cards_BuyCode from "./Cards_BuyCode"
import MenuBar from './MenuBar'

import AOS from 'aos'
import "aos/dist/aos.css"

const BuyCode = () => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'

    const langOptions = [
        {
          key: 'English',
          text: 'EN',
          code: 'en',
          image: <Flag name='gb' />
        },
        {
          key: 'Deutsch',
          text: 'DE',
          code: 'de',
          image: <Flag name='de' />
        }
      ]

      const [standardSelected, setStandardSelected] = useState(true)
      const [premiumSelected, setPremiumSelected] = useState(false)
      const [exclusiveSelected, setExclusiveSelected] = useState(false)

      //const [activeItem, setActiveItem] = useState('Laptop Item')
      //const [showModal, setShowModal] = useState(false)

      const [activeItem, setActiveItem] = useState('buy')
      const [activeCategory, setActiveCategory] = useState('exclusive')

      const [deviceMobile, setDeviceMobile] = useState(false);

      const checkDevice = () => {
        if (window.innerWidth <= 950) {
          setDeviceMobile(true);
        } else {
          setDeviceMobile(false);
        }
      };

      useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 500,
            easing: "ease-out-cubic",
        })
        checkDevice();
        window.addEventListener("resize", checkDevice);
        return () => window.removeEventListener("resize", checkDevice);
      }, [activeCategory]);

      const [visible, setVisible] = React.useState(false)

      let navigate = useNavigate(); 
      const routeChange = (x) =>{ 
        let path = `${x}`; 
        navigate(path);
      }

      return (
          <>
            <div className='App'>

            <MenuBar active={'buy'}/>

            {deviceMobile ?
                <div className='Container_zero_padding_not_center'>
                    <div id='br_replacer' />
                    
                    <Menu size='large' borderless icon='labeled'>
                        <Menu.Menu>
                            <Menu.Item color='orange' name='exclusive' active={activeCategory === 'exclusive'} onClick={() => setActiveCategory('exclusive')}>
                                <Header color={activeCategory === 'exclusive' ? 'orange' : null}>Exclusive</ Header>
                                <Grid>
                                    <Grid.Row>
                                        <Icon name='star' size='large' /><Icon name='star' size='large' /><Icon name='star' size='large' />
                                    </Grid.Row>
                                </Grid>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            <Menu.Item color='orange' name='premium' active={activeCategory === 'premium'} onClick={() => setActiveCategory('premium')}>
                                <Header color={activeCategory === 'premium' ? 'orange' : null}>Premium</ Header>
                                <Grid>
                                    <Grid.Row>
                                        <Icon name='star' size='large' /><Icon name='star' size='large' />
                                    </Grid.Row>
                                </Grid>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            <Menu.Item color='orange' name='standard' active={activeCategory === 'standard'} onClick={() => setActiveCategory('standard')}>
                                <Header color={activeCategory === 'standard' ? 'orange' : null}>Standard</ Header>
                                <Grid>
                                    <Grid.Row>
                                        <Icon name='star' size='large' />
                                    </Grid.Row>
                                </Grid>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    

                <Menu borderless fixed='bottom'>
                        <Menu.Menu position='left'>
                            
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            <Menu.Item>
                            <div className='Container_small'>
                                {
                                    activeCategory==="exclusive" ?
                                    <a target="_blank" rel="noopener noreferrer" href="https://sowl.co/s/dWq9E">
                                        <Button
                                            compact={true}
                                            color='orange'
                                            size='large'
                                        >
                                        {t('exclusive_buy_code_now_for')} €100,-
                                        </Button>
                                    </a> : activeCategory==="premium" ?
                                    <a target="_blank" rel="noopener noreferrer" href="https://sowl.co/s/gGc3E">
                                        <Button
                                            compact={true}
                                            color='orange'
                                            size='large'
                                        >
                                        {t('premium_buy_code_now_for')} €50,-
                                        </Button>
                                    </a> :
                                    <a target="_blank" rel="noopener noreferrer" href="https://sowl.co/s/QHjvF">
                                        <Button
                                            compact={true}
                                            color='orange'
                                            size='large'
                                        >
                                        {t('standard_buy_code_now_for')} €20,-
                                        </Button>
                                    </a>
                                }
                            </div>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            
                        </Menu.Menu>
                    </Menu>
                    
                </div>:

                <div className='Container_big_padding'>
                    <div id='br_replacer' />

                    <div className='Container_small'>
                    <Menu size='large' borderless icon='labeled'>
                        <Menu.Menu>
                            <Menu.Item color='orange' name='exclusive' active={activeCategory === 'exclusive'} onClick={() => setActiveCategory('exclusive')}>
                                <Header id='menu_padding' color={activeCategory === 'exclusive' ? 'orange' : null}>Exclusive</ Header>
                                <Grid>
                                    <Grid.Row>
                                        <Icon name='star' size='big' /><Icon name='star' size='big' /><Icon name='star' size='big' />
                                    </Grid.Row>
                                </Grid>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            <Menu.Item color='orange' name='premium' active={activeCategory === 'premium'} onClick={() => setActiveCategory('premium')}>
                                <Header id='menu_padding' color={activeCategory === 'premium' ? 'orange' : null}>Premium</ Header>
                                <Grid>
                                    <Grid.Row>
                                        <Icon name='star' size='big' /><Icon name='star' size='big' />
                                    </Grid.Row>
                                </Grid>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            <Menu.Item color='orange' name='standard' active={activeCategory === 'standard'} onClick={() => {
                                AOS.init({
                                    disable: "phone",
                                    duration: 500,
                                    easing: "ease-out-cubic",
                                })
                                setActiveCategory('standard')
                                }}>
                                <Header id='menu_padding' color={activeCategory === 'standard' ? 'orange' : null}>Standard</ Header>
                                <Grid>
                                    <Grid.Row>
                                        <Icon name='star' size='big' />
                                    </Grid.Row>
                                </Grid>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    </div>

                    <Menu borderless fixed='bottom'>
                        <Menu.Menu position='left'>
                            
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            <Menu.Item>
                            <div className='Container_small'>
                                {
                                    activeCategory==="exclusive" ?
                                    <a target="_blank" rel="noopener noreferrer" href="https://sowl.co/s/dWq9E">
                                        <Button
                                            compact={deviceMobile ? true : false}
                                            color='orange'
                                            size='huge'
                                        >
                                        {t('exclusive_buy_code_now_for')} €100,-
                                        </Button>
                                    </a> : activeCategory==="premium" ?
                                    <a target="_blank" rel="noopener noreferrer" href="https://sowl.co/s/gGc3E">
                                        <Button
                                            compact={deviceMobile ? true : false}
                                            color='orange'
                                            size='huge'
                                        >
                                        {t('premium_buy_code_now_for')} €50,-
                                        </Button>
                                    </a> :
                                    <a target="_blank" rel="noopener noreferrer" href="https://sowl.co/s/QHjvF">
                                        <Button
                                            compact={deviceMobile ? true : false}
                                            color='orange'
                                            size='huge'
                                        >
                                        {t('standard_buy_code_now_for')} €20,-
                                        </Button>
                                    </a>
                                }
                            </div>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            
                        </Menu.Menu>
                    </Menu>
                </div>
                }
                
{/* MAIN */}    <div data-aos="fade-right">
                    <Cards_BuyCode category={activeCategory} />
                </div>
            </div>
                            
{/*Footer*/}

            <div className="Container">
                <List bulleted horizontal link size='large'>
                    <List.Item as='a' onClick={() => routeChange('terms')}>
                    {t('terms')}
                    </List.Item>
                    <List.Item as='a' onClick={() => routeChange('data_protection')}>
                    {t('data_protection')}
                    </List.Item>
                    <List.Item as='a' onClick={() => routeChange('imprint')}>
                    {t('imprint')}
                    </List.Item>
                </List>
                <div className="Container_middle">
                    <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
                </div>
                
                <div id='br_replacer' />
                <div id='br_replacer' />
                <div id='br_replacer' />
                <div id='br_replacer' />
            </div>
          </>
      )
} 

export default BuyCode