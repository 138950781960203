import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Dropdown, Flag, Icon, Header, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import img from "./assets/Logo.jpg";
import img_mobile from "./assets/Logo_mobile.png";

const MenuBar = ({ active }) => {
  const { t } = useTranslation();
  const [currentLanguageCode, setActiveLanguageCode] = useState(cookies.get("i18next") || "en");

  const [deviceMobile, setDeviceMobile] = useState(false);

  const langOptions = [
    {
      key: "English",
      text: "EN",
      code: "en",
      image: <Flag name="gb" />,
    },
    {
      key: "Deutsch",
      text: "DE",
      code: "de",
      image: <Flag name="de" />,
    },
  ];

  const checkDevice = () => {
    if (window.innerWidth <= 950) {
      setDeviceMobile(true);
    } else {
      setDeviceMobile(false);
    }
  };

  useEffect(() => {
    checkDevice();
    window.addEventListener("resize", checkDevice);
    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  const [activeItem, setActiveItem] = useState(active);

  let navigate = useNavigate();
  const routeChange = (x) => {
    let path = `${x}`;
    navigate(path);
  };

  return (
    <>
      {deviceMobile ? (
        <Menu fixed="top" borderless>
          <Menu.Menu position="left">
            <Dropdown icon={<Icon name="language" size="big" color="orange" />} item>
              <Dropdown.Menu icon="language">
                {langOptions.map(({ key, text, code, image }) => (
                  <Dropdown.Item
                    key={key}
                    icon={image}
                    text={text}
                    onClick={() => {
                      i18next.changeLanguage(code);
                      setActiveLanguageCode(code);
                    }}
                    disabled={code === currentLanguageCode}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item
              onClick={() => {
                routeChange("/");
                setActiveItem("buy");
              }}
            >
              <Image src={img_mobile} />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Dropdown item icon={<Icon name="bars" size="big" color="orange" />}>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    routeChange("/");
                    setActiveItem("buy");
                  }}
                  text={<Header color={activeItem === "buy" ? "orange" : null}><Icon name="cart" /> {t("buy_codes")}</Header>}
                />
                <Dropdown.Item
                  onClick={() => {
                    navigate("/redeem");
                    setActiveItem("redeem");
                  }}
                  text={<Header color={activeItem === "redeem" ? "orange" : null}><Icon name="gift" /> {t("redeem_code")}</Header>}
                />
                <Dropdown.Item
                  onClick={() => {
                    navigate("/how_it_works");
                    setActiveItem("how_it_works");
                  }}
                  text={<Header color={activeItem === "how_it_works" ? "orange" : null}><Icon name="question" /> {t("how_it_works")}</Header>}
                />
                <Dropdown.Item
                  onClick={() => {}}
                  text={<Header color="red"><Icon name="handshake" /> {t("to_b2b")}</Header>}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      ) : (
        <div className="Container_big_padding">
          <Menu fixed="top" borderless compact>
            <Menu.Menu position="left">
              <Menu.Item
                onClick={() => {
                  routeChange("/");
                  setActiveItem("buy");
                }}
              >
                <Image src={img} size="medium" />
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position="right">
              <Menu.Item
                color="orange"
                name="buy"
                active={activeItem === "buy"}
                onClick={() => {
                  routeChange("/");
                  setActiveItem("buy");
                }}
              >
                <Header color={activeItem === "buy" ? "orange" : null}><Icon name="cart" /> {t("buy_codes")}</Header>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu>
              <Menu.Item
                color="orange"
                name="redeem"
                active={activeItem === "redeem"}
                onClick={() => {
                  navigate("/redeem");
                  setActiveItem("redeem");
                }}
              >
                <Header color={activeItem === "redeem" ? "orange" : null}><Icon name="gift" /> {t("redeem_code")}</Header>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu>
              <Menu.Item
                color="orange"
                name="how_it_works"
                active={activeItem === "how_it_works"}
                onClick={() => {
                  navigate("/how_it_works");
                  setActiveItem("how_it_works");
                }}
              >
                <Header color={activeItem === "how_it_works" ? "orange" : null}><Icon name="question" /> {t("how_it_works")}</Header>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu>
              <Menu.Item
                color="red"
                name={t('to_b2b')}
                onClick={() => {}}
              >
                <Header color="red"><Icon name="handshake" /> {t('to_b2b')}</Header>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu>
              <Menu.Item>
                <Dropdown
                  text={currentLanguageCode === "de" ? <Flag name="de" size="big" /> : <Flag name="gb" size="big" />}
                  compact
                  selection
                >
                  <Dropdown.Menu icon="language">
                    {langOptions.map(({ key, text, code, image }) => (
                      <Dropdown.Item
                        key={key}
                        icon={image}
                        text={text}
                        onClick={() => {
                          i18next.changeLanguage(code);
                          setActiveLanguageCode(code);
                        }}
                        disabled={code === currentLanguageCode}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
      )}
    </>
  );
};

export default MenuBar;
