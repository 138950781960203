import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flag, Header, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import MenuBar from "./MenuBar";

const Copyright = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";

    const [deviceMobile, setDeviceMobile] = useState(false);

    const langOptions = [
        {
            key: "English",
            text: "EN",
            code: "en",
            image: <Flag name="gb" />,
        },
        {
            key: "Deutsch",
            text: "DE",
            code: "de",
            image: <Flag name="de" />,
        },
    ];

    useEffect(() => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent,
            )
        ) {
            setDeviceMobile(true);
        } else {
            setDeviceMobile(false);
        }
    }, []);

    const [activeItem, setActiveItem] = useState("");

    let navigate = useNavigate();
    const routeChange = (x) => {
        let path = `${x}`;
        navigate(path);
    };

    return (
        <>
            <div className="App">

                <MenuBar />
                <Header size="huge" textAlign="center">
                    {t("copyright")}
                </Header>
            </div>

            {/*Footer*/}

            <div className="Container">
                <List centered bulleted horizontal link size="large">
                    <List.Item as="a" onClick={() => navigate("/copyright")}>
                        {t("copyright")}
                    </List.Item>
                    <List.Item as="a" onClick={() => navigate("/terms")}>
                        {t("terms")}
                    </List.Item>
                    <List.Item
                        as="a"
                        onClick={() => navigate("/data_protection")}
                    >
                        {t("data_protection")}
                    </List.Item>
                    <List.Item as="a" onClick={() => navigate("/imprint")}>
                        {t("imprint")}
                    </List.Item>
                </List>
                <div className="Container_middle">
                    <Button circular color="linkedin" icon="linkedin" />
                    <Button circular color="xing" icon="xing" />
                </div>
            </div>
        </>
    );
};

export default Copyright;
