import React from "react";
import { Button } from "semantic-ui-react";

const CardButton = ({ onClick, text }) => {
    return (
        <Button circular fluid size='huge' positive
        onClick={onClick}
        >
        {text}
        </Button>
    )
}

export default CardButton;