import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { Button, Flag, Icon, Divider, List, Modal, Form, Message } from 'semantic-ui-react'
import { useHover } from "react-hookedup"
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import cookies from 'js-cookie'
import axios from "axios"

import './App.css'
import ImageSlider from './Carousel'
import PresentDelivery from "./PresentDelivery";
import MenuBar from "./MenuBar";

import ReCAPTCHA from "react-google-recaptcha";

import AOS from 'aos'
import "aos/dist/aos.css"
      
function exampleReducer(state, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open_dimmer: true, dimmer: action.dimmer}
    case 'CLOSE_MODAL':
       return { open_dimmer: false }
    default: 
       throw new Error()
  }
}

const App = () => {
  const { t } = useTranslation()
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const [presentCode, setPresentCode] = useState("")
  const [presentCategory, setPresentCategory] = useState("")
  const [showCodeInputError, setshowCodeInputError] = useState(false)
  const [showRECAPTCHAError, setshowRECAPTCHAError] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)

  const [delivery_address_confirmed, setDelivery_address_confirmed] = useState(false)
  const [present_chosen, setPresentChosen] = useState("fullscreen")

  let navigate = useNavigate(); 
  const routeChange = (x) =>{ 
    let path = `${x}`;

    //navigate('/'+path);
    navigate(path)
  }

  const verifyToken = async (token) => {
    try{
    let response = await axios.post(`http://localhost:4000/verify-token`,{
       
    secret:process.env.REACT_APP_SECRET_KEY,
        token
    },console.log(token));
    return response.data;
    }catch(error){
    console.log("error ",error);
    }
  }

  const checkPresentCode = async (pk) => {
    try {
      let token = captchaRef.current.getValue();
      setshowCodeInputError(false);
      if(token){
            setshowRECAPTCHAError(false);
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                //const pres = "standard"
                setPresentCategory(res.data.Item.category)
                //console.log(presentCategory)
                setshowCodeInputError(false);
                setButtonLoading(false);
                dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' });
            }
            else {
              setshowCodeInputError(true);
              console.log('This code does not exist or has already been used.');
            }
          }else{
            setshowCodeInputError(true)
            setshowRECAPTCHAError(true);
          }
      
    }
    catch (err) {
      console.log(`An error has occurred: ${err}`);
      setshowCodeInputError(true);
    }
  }


  const { hovered, bind } = useHover();

  const langOptions = [
    {
      key: 'English',
      text: 'EN',
      code: 'en',
      image: <Flag name='gb' />
    },
    {
      key: 'Deutsch',
      text: 'DE',
      code: 'de',
      image: <Flag name='de' />
    }
  ]

  const [state, dispatch] = React.useReducer(exampleReducer, {
    open_dimmer: false,
    dimmer: undefined,
  })
  const { open_dimmer, dimmer } = state

  const [activeItem, setActiveItem] = useState('redeem')

  const [deviceMobile, setDeviceMobile] = useState(false);

  const captchaRef = useRef(null);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceMobile(true);
    } else {
      setDeviceMobile(false);
    }
    AOS.init({
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    })
  }, []);

  function errorMessage(){
    if (showRECAPTCHAError){
    return t('RECAPTCHA_error')
    } else if (showCodeInputError){
      return t('invalid_code')
    } else {
      return null
    }
  }

  const deliveryConfirmed = () => {
    console.log("Delivery address confirmed.")
    setDelivery_address_confirmed(true)
  }

  const presentChosen = () => {
    console.log("Present chosen.")
    setPresentChosen("large")
  }

  const presentChosenReverse = () => {
    console.log("Present chosen.")
    setPresentChosen("fullscreen")
  }

  return (
    <>
      <div className='App'>

      <MenuBar active={'redeem'}/>

{/* MAIN */}

      <div data-aos="fade-right">
      <ImageSlider />
      </div>

      <div data-aos="fade-right">
      <div className='Container'>   
      <Form error={showCodeInputError}>
        <Form.Input {...bind}
          error={showCodeInputError}
          focus={hovered ? true : false}
          icon={hovered ? <Icon color='orange' name='gift' /> : <Icon color='black' name='gift' />}
          size='massive'
          type='text'
          placeholder={t('enter_code')}
          value={presentCode}
          onChange={(e) => setPresentCode(e.target.value)}
        />
        <Message
          error
          content={errorMessage()}
        />
      </Form>
      </div>
      </div>

      <div data-aos="fade-right">
      <div className="Container_small">
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
      </div>
      </div>
      
      <div data-aos="fade-right">
      <div className='Container'>
        <Button
          color='orange'
          size='huge'
          onClick={() => {
            checkPresentCode(presentCode)
          }}
        >
          {t('choose_present')}
        </Button>
      </div>
      </div>

      <Modal
        closeIcon
        size={present_chosen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        dimmer={dimmer}
        open={open_dimmer}
        onClose={() => {
          dispatch({ type: 'CLOSE_MODAL' })
          setPresentChosen("fullscreen")
          if (delivery_address_confirmed){
            navigate('/');
          }
        }}
      >
        <Modal.Content>
          <PresentDelivery presentCode={presentCode} category={presentCategory} deliveryConfirmed={deliveryConfirmed} presentChosen={presentChosen} presentChosenReverse={presentChosenReverse}/>
        </Modal.Content>
      </Modal>

      <Divider horizontal>{t('or')}</Divider>
      
      <div data-aos="fade-right">
      <div className='Container'>
        <Button inverted
          color='orange'
          size='huge'
          onClick= {() => routeChange('/')}
        >
        {t('say_thank_you')}
        </Button>
      </div>
      </div>
    </div>

{/*Footer*/}

    <div className="Container">
      <List centered bulleted horizontal link size='large'>
        <List.Item as='a' onClick={() => navigate('/terms')}>
          {t('terms')}
        </List.Item>
        <List.Item as='a' onClick={() => navigate('/data_protection')}>
          {t('data_protection')}
        </List.Item>
        <List.Item as='a' onClick={() => navigate('/imprint')}>
          {t('imprint')}
        </List.Item>
      </List>
      <div className="Container_middle">
        <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
      </div>
    </div>
    </>
  );
}

export default App;