import './App.css'
import React, { useState, useEffect } from "react"
import { Button, Flag, Icon, Header, Card, Image, Label, Form, Step, Divider, Segment, Container, Message, Grid } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import i18next, { use } from 'i18next'
import cookies from 'js-cookie'
import { useNavigate } from "react-router-dom"
import emailjs from 'emailjs-com'
import axios from 'axios'
import useHover from 'react-use-hover';

import SelectedPresent from './SelectedPresent'
import CardButton from './components/CardButton'

const PresentDelivery = ({ presentCode, category, deliveryConfirmed, presentChosen, presentChosenReverse }) => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'
    
    const [open, setOpen] = React.useState(false)

    const [progress, setProgress] = useState(0)

    const [isHovering1, hoverProps1] = useHover();
    const [isHovering2, hoverProps2] = useHover();
    const [isHovering3, hoverProps3] = useHover();
    const [isHovering4, hoverProps4] = useHover();
    const [isHovering5, hoverProps5] = useHover();
    const [isHovering6, hoverProps6] = useHover();
    const [isHovering7, hoverProps7] = useHover();
    const [isHovering8, hoverProps8] = useHover();
    const [isHovering9, hoverProps9] = useHover();
    const [isHovering10, hoverProps10] = useHover();
    const [isHovering11, hoverProps11] = useHover();
    
    // Order address variables
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [addressline1, setAdressline1] = useState("")
    const [addressline2, setAdressline2] = useState("")
    const [zipcode, setZipcode] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("Deutschland")
    const [email, setEmail] = useState("")
    const [mobil, setMobil] = useState("")
    const [birthday, setBirthday] = useState("")
    const [clientIP, setClientIP] = useState("")

    const current = new Date()

    const [checked, setChecked] = useState(false)
    const [showError, setShowError] = useState(false)

    const [showError_FirstName, setShowError_FirstName] = useState(false)
    const [showError_LastName, setShowError_LastName] = useState(false)
    const [showError_addressline1, setShowError_addressline1] = useState(false)
    const [showError_zipcode, setShowError_zipcode] = useState(false)
    const [showError_city, setShowError_city] = useState(false)
    const [showError_email, setShowError_email] = useState(false)

    const [product_1_selected, setProduct_1_selected] = useState(false)
    const [product_2_selected, setProduct_2_selected] = useState(false)
    const [product_3_selected, setProduct_3_selected] = useState(false)
    const [product_4_selected, setProduct_4_selected] = useState(false)
    const [product_5_selected, setProduct_5_selected] = useState(false)
    const [product_6_selected, setProduct_6_selected] = useState(false)
    const [product_7_selected, setProduct_7_selected] = useState(false)
    const [product_8_selected, setProduct_8_selected] = useState(false)
    const [product_9_selected, setProduct_9_selected] = useState(false)
    const [product_10_selected, setProduct_10_selected] = useState(false)
    const [product_11_selected, setProduct_11_selected] = useState(false)
    const [product_12_selected, setProduct_12_selected] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState("")

    var templateParams = {
        code: presentCode,
        category: category,
        product: selectedProduct,
        firstName: firstName,
        lastName: lastName,
        addressline1: addressline1,
        addressline2: addressline2,
        zipcode: zipcode,
        city: city,
        country: country
    };
    
    const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const [deviceMobile, setDeviceMobile] = useState(false);

    useEffect(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
          navigator.userAgent
        )
      ) {
        setDeviceMobile(true);
        getIP();
      } else {
        setDeviceMobile(false);
        getIP();
      }
    }, []);

    let navigate = useNavigate(); 
    const routeChange = (x) =>{ 
      let path = `${x}`; 
      navigate(path);
    }

    function returnCategoryLabel(category) {
        if (category === "standard"){
            return <Label attached='top left' size='large' color='orange'>Standard</Label>
        }
        if (category === "premium"){
            return <Label attached='top left' size='large' color='orange'>Premium</Label>
        }
        if (category === "exclusive"){
            return <Label attached='top left' size='large' color='orange'>Exclusive</Label>
        }
    }

    const getIP = async () => {
        try {
            await fetch('https://api.ipify.org/?format=json')
            .then(results => results.json())
            .then(data => {
                setClientIP(data.ip)
                setClientIP((state) => {
                    console.log(state);
                    
                    return state;
                  });
            })
        }
        catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }
    
    const saveRecipientInformation = async (id, firstName, lastName, addressline1, addressline2, zipcode, city, country, email, mobil, birthday, ip) => {
        try {
            const params = {
                "id": id,
                "firstName": firstName,
                "lastName": lastName,
                "addressline1": addressline1,
                "addressline2": addressline2,
                "zipcode": zipcode,
                "city": city,
                "country": country,
                "email": email,
                "mobil": mobil,
                "birthday": birthday,
                "date": `${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()}`,
                "ip": ip
            }

            await axios.post(`${process.env.REACT_APP_RECIPIENT}/recipients/${id}`, params)
        }
        catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }
    
    const validatePresentCode = async (pk) => {
        try {
            const params = {
                "PK": pk,
                "validStatus": 'invalid'
            }
            await axios.patch(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`, params);
            console.log("updated")
        }
        catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    const sendEmail = () => {
        emailjs.send('service_m94q71o', 'template_49w93k6', templateParams, 'oDnmn9WLRHUpvjmYU')
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('FAILED...', error);
        })
    }
  
    const confirmCheckPresentCode = async (pk) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                saveRecipientInformation(presentCode, firstName, lastName, addressline1, addressline2, zipcode, city, country, email, mobil, birthday, clientIP)
                validatePresentCode(presentCode)
                sendEmail()
                deliveryConfirmed()
                setProgress(progress + 1)
            }
        else {
            setProgress(10);
            console.log('This code has already been used while you have been selecting your present.');
        }
        }
        catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    const errorPointingDirection = () => {
        if (deviceMobile){
            return t('must_agree_terms')
        } else{
            return (
                {
                    content: t('must_agree_terms'),
                    pointing: 'left',
                }
            )
        }
    }

    const CardSelectedOrHover = (productnumber, isHovering) => {
        if (productnumber){
            return ("card-selected")
        }
        else if (isHovering){
            return ("card")
        }
        else{
            return false
        }
    }

    // Present Selection for standard products
    if (progress === 0 && category === "standard") {return (
        <>
        <div className='Container_Modal'>
            {deviceMobile ? 
            <Step.Group unstackable widths={3} size='mini'>
                <Step active>
                <Icon name='gift' color='orange'/>
                </Step>

                <Step disabled>
                <Icon name='truck' color='orange'/>
                </Step>

                <Step disabled>
                <Icon name='info' color='orange'/>
                </Step>
            </Step.Group> : 
            <Step.Group unstackable widths={3} size='mini'>
                <Step active>
                <Icon name='gift' color='orange'/>
                <Step.Content>
                    <Step.Title>1. {t('choose_present')}</Step.Title>
                </Step.Content>
                </Step>

                <Step disabled>
                <Icon name='truck' color='orange'/>
                <Step.Content>
                    <Step.Title>2. {t('delivery_details')}</Step.Title>
                </Step.Content>
                </Step>

                <Step disabled>
                <Icon name='info' color='orange'/>
                <Step.Content>
                    <Step.Title>3. {t('confirmation')}</Step.Title>
                </Step.Content>
                </Step>
            </Step.Group>}

            <Divider />

            <Card.Group itemsPerRow={4} centered stackable>
                <Card {...hoverProps1} id={CardSelectedOrHover(product_1_selected, isHovering1)} onClick={() => {
                    setProduct_1_selected(true)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Chocodrink")
                }
                }>
                    <Image src="/images/chocodrink_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_1_selected ? 'card-selected' : false}>{t('standard_chocodrink_card_header')}</Header></Card.Header>
                        <Card.Description id={product_1_selected ? 'card-selected' : false}>
                            {t('standard_chocodrink_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_1_selected ? 'card-selected' : false}>{t('standard_chocodrink_card_meta')} <Flag name='fr' /></Card.Meta>
                        <Card.Description id={product_1_selected ? 'card-selected' : false}>
                            {product_1_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_chocodrink_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps2} id={CardSelectedOrHover(product_2_selected, isHovering2)} onClick={() => {
                    setProduct_2_selected(true)
                    setProduct_1_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Teeflower")
                }
                }>
                    <Image src="/images/tea_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_2_selected ? 'card-selected' : false}>{t('standard_tee_card_header')}</Header></Card.Header>
                        <Card.Description id={product_2_selected ? 'card-selected' : false}>
                            {t('standard_tee_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_2_selected ? 'card-selected' : false}>{t('standard_tee_card_meta')} <Flag name='cn' /></Card.Meta>
                        <Card.Description id={product_2_selected ? 'card-selected' : false}>
                            {product_2_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_tee_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps3} id={CardSelectedOrHover(product_3_selected, isHovering3)} onClick={() => {
                    setProduct_3_selected(true)
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Magnetic Cube")
                }
                }>
                    <Image src="/images/magnets_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_3_selected ? 'card-selected' : false}>{t('standard_magnet_card_header')}</Header></Card.Header>
                        <Card.Description id={product_3_selected ? 'card-selected' : false}>
                            {t('standard_magnet_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_3_selected ? 'card-selected' : false}>{t('standard_magnet_card_meta')} <Flag name='de' /></Card.Meta>
                        <Card.Description id={product_3_selected ? 'card-selected' : false}>
                            {product_3_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_magnet_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps4} id={CardSelectedOrHover(product_4_selected, isHovering4)} onClick={() => {
                    setProduct_4_selected(true)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Licorice")
                }
                }>
                    <Image src="/images/licorice_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_4_selected ? 'card-selected' : false}>{t('standard_licorice_card_header')}</Header></Card.Header>
                        <Card.Description id={product_4_selected ? 'card-selected' : false}>
                            {t('standard_licorice_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_4_selected ? 'card-selected' : false}>{t('standard_licorice_card_meta')} <Flag name='dk' /><Flag name='it' /></Card.Meta>
                        <Card.Description id={product_4_selected ? 'card-selected' : false}>
                            {product_4_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_licorice_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>

            <Divider />

            <Label attached='top left' size='large' color='orange'>Standard</Label>

            <div className='Container_small_right'>
                <Button positive disabled={product_1_selected || product_2_selected || product_3_selected || product_4_selected || product_5_selected || product_6_selected || product_7_selected || product_8_selected || product_9_selected ? false : true}
                        onClick={() => {
                            setProgress(progress + 1)
                            presentChosen()
                        }}
                >
                    {t('continue')}
                </Button>
            </div>
        </div>
        </>
    )}

    // Present Selection for premium products
    if (progress === 0 && category === "premium") {return (
        <>
        <div className='Container_Modal'>
            {deviceMobile ? 
            <Step.Group unstackable widths={3} size='mini'>
                <Step active>
                <Icon name='gift' color='orange'/>
                </Step>

                <Step disabled>
                <Icon name='truck' color='orange'/>
                </Step>

                <Step disabled>
                <Icon name='info' color='orange'/>
                </Step>
            </Step.Group> : 
            <Step.Group unstackable widths={3} size='mini'>
                <Step active>
                <Icon name='gift' color='orange'/>
                <Step.Content>
                    <Step.Title>1. {t('choose_present')}</Step.Title>
                </Step.Content>
                </Step>

                <Step disabled>
                <Icon name='truck' color='orange'/>
                <Step.Content>
                    <Step.Title>2. {t('delivery_details')}</Step.Title>
                </Step.Content>
                </Step>

                <Step disabled>
                <Icon name='info' color='orange'/>
                <Step.Content>
                    <Step.Title>3. {t('confirmation')}</Step.Title>
                </Step.Content>
                </Step>
            </Step.Group>}

            <Divider />

            <Card.Group style={deviceMobile ? {} : {overflow: 'auto', maxHeight: 610 }} itemsPerRow={4} centered doubling stackable>
            <Card {...hoverProps1} id={CardSelectedOrHover(product_1_selected, isHovering1)} onClick={() => {
                    setProduct_1_selected(true)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Chocodrink")
                }
                }>
                    <Image src="/images/chocodrink_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_1_selected ? 'card-selected' : false}>{t('standard_chocodrink_card_header')}</Header></Card.Header>
                        <Card.Description id={product_1_selected ? 'card-selected' : false}>
                            {t('standard_chocodrink_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_1_selected ? 'card-selected' : false}>{t('standard_chocodrink_card_meta')} <Flag name='fr' /></Card.Meta>
                        <Card.Description id={product_1_selected ? 'card-selected' : false}>
                            {product_1_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_chocodrink_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps2} id={CardSelectedOrHover(product_2_selected, isHovering2)} onClick={() => {
                    setProduct_2_selected(true)
                    setProduct_1_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Teeflower")
                }
                }>
                    <Image src="/images/tea_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_2_selected ? 'card-selected' : false}>{t('standard_tee_card_header')}</Header></Card.Header>
                        <Card.Description id={product_2_selected ? 'card-selected' : false}>
                            {t('standard_tee_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_2_selected ? 'card-selected' : false}>{t('standard_tee_card_meta')} <Flag name='cn' /></Card.Meta>
                        <Card.Description id={product_2_selected ? 'card-selected' : false}>
                            {product_2_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_tee_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps3} id={CardSelectedOrHover(product_3_selected, isHovering3)} onClick={() => {
                    setProduct_3_selected(true)
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Magnetic Cube")
                }
                }>
                    <Image src="/images/magnets_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_3_selected ? 'card-selected' : false}>{t('standard_magnet_card_header')}</Header></Card.Header>
                        <Card.Description id={product_3_selected ? 'card-selected' : false}>
                            {t('standard_magnet_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_3_selected ? 'card-selected' : false}>{t('standard_magnet_card_meta')} <Flag name='de' /></Card.Meta>
                        <Card.Description id={product_3_selected ? 'card-selected' : false}>
                            {product_3_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_magnet_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps4} id={CardSelectedOrHover(product_4_selected, isHovering4)} onClick={() => {
                    setProduct_4_selected(true)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Licorice")
                }
                }>
                    <Image src="/images/licorice_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_4_selected ? 'card-selected' : false}>{t('standard_licorice_card_header')}</Header></Card.Header>
                        <Card.Description id={product_4_selected ? 'card-selected' : false}>
                            {t('standard_licorice_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_4_selected ? 'card-selected' : false}>{t('standard_licorice_card_meta')} <Flag name='dk' /><Flag name='it' /></Card.Meta>
                        <Card.Description id={product_4_selected ? 'card-selected' : false}>
                            {product_4_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_licorice_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps5} id={CardSelectedOrHover(product_5_selected, isHovering5)} onClick={() => {
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(true)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Balsamico and Olive oil")
                }
                }>
                    <Image src="/images/balsamico-olive_premium.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_5_selected ? 'card-selected' : false}>{t('premium_balsamico_card_header')}</Header></Card.Header>
                        <Card.Description id={product_5_selected ? 'card-selected' : false}>
                            {t('premium_balsamico_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_5_selected ? 'card-selected' : false}>{t('premium_balsamico_card_meta')} <Flag name='gr' /><Flag name='it' /></Card.Meta>
                        <Card.Description id={product_5_selected ? 'card-selected' : false}>
                            {product_5_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_balsamico_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps6} id={CardSelectedOrHover(product_6_selected, isHovering6)} onClick={() => {
                    setProduct_2_selected(false)
                    setProduct_1_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(true)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Pen")
                }
                }>
                    <Image src="/images/parker_premium.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_6_selected ? 'card-selected' : false}>{t('premium_pen_card_header')}</Header></Card.Header>
                        <Card.Description id={product_6_selected ? 'card-selected' : false}>
                            {t('premium_pen_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_6_selected ? 'card-selected' : false}>{t('premium_pen_card_meta')} <Flag name='fr' /></Card.Meta>
                        <Card.Description id={product_6_selected ? 'card-selected' : false}>
                            {product_6_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_pen_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps7} id={CardSelectedOrHover(product_7_selected, isHovering7)} onClick={() => {
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(true)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Coffee")
                }
                }>
                    <Image src="/images/wine_exclusive.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_7_selected ? 'card-selected' : false}>{t('premium_coffee_card_header')}</Header></Card.Header>
                        <Card.Description id={product_7_selected ? 'card-selected' : false}>
                            {t('premium_coffee_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_7_selected ? 'card-selected' : false}>{t('premium_coffee_card_meta')} <Flag name='pa' /></Card.Meta>
                        <Card.Description id={product_7_selected ? 'card-selected' : false}>
                            {product_7_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_coffee_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps8} id={CardSelectedOrHover(product_8_selected, isHovering8)} onClick={() => {
                    setProduct_4_selected(false)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(true)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Lindt Chocolate")
                }
                }>
                    <Image src="/images/chocolate_premium.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_8_selected ? 'card-selected' : false}>{t('premium_choco_card_header')}</Header></Card.Header>
                        <Card.Description id={product_8_selected ? 'card-selected' : false}>
                            {t('premium_choco_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_8_selected ? 'card-selected' : false}>{t('premium_choco_card_meta')} <Flag name='de' /></Card.Meta>
                        <Card.Description id={product_8_selected ? 'card-selected' : false}>
                            {product_8_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_choco_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>

            <Divider />

            <Label attached='top left' size='large' color='orange'>Premium</Label>

            <div className='Container_small_right'>
                <Button positive disabled={product_1_selected || product_2_selected || product_3_selected || product_4_selected || product_5_selected || product_6_selected || product_7_selected || product_8_selected || product_9_selected ? false : true}
                        onClick={() => {
                            setProgress(progress + 1)
                            presentChosen()
                        }}
                >
                    {t('continue')}
                </Button>
            </div>
        </div>
        </>
    )}

    // Present Selection for exclusive products
    if (progress === 0 && category === "exclusive") {return (
        <>
        <div className='Container_Modal'>
            {deviceMobile ? 
            <Step.Group unstackable widths={3} size='mini'>
                <Step active>
                <Icon name='gift' color='orange'/>
                </Step>

                <Step disabled>
                <Icon name='truck' color='orange'/>
                </Step>

                <Step disabled>
                <Icon name='info' color='orange'/>
                </Step>
            </Step.Group> : 
            <Step.Group unstackable widths={3} size='mini'>
                <Step active>
                <Icon name='gift' color='orange'/>
                <Step.Content>
                    <Step.Title>1. {t('choose_present')}</Step.Title>
                </Step.Content>
                </Step>

                <Step disabled>
                <Icon name='truck' color='orange'/>
                <Step.Content>
                    <Step.Title>2. {t('delivery_details')}</Step.Title>
                </Step.Content>
                </Step>

                <Step disabled>
                <Icon name='info' color='orange'/>
                <Step.Content>
                    <Step.Title>3. {t('confirmation')}</Step.Title>
                </Step.Content>
                </Step>
            </Step.Group>}

            <Divider />

            <Card.Group style={deviceMobile ? {} : {overflow: 'auto', maxHeight: 610 }} itemsPerRow={4} centered doubling stackable>
            <Card {...hoverProps1} id={CardSelectedOrHover(product_1_selected, isHovering1)} onClick={() => {
                    setProduct_1_selected(true)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Chocodrink")
                }
                }>
                    <Image dimmer src="/images/chocodrink_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_1_selected ? 'card-selected' : false}>{t('standard_chocodrink_card_header')}</Header></Card.Header>
                        <Card.Description id={product_1_selected ? 'card-selected' : false}>
                            {t('standard_chocodrink_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_1_selected ? 'card-selected' : false}>{t('standard_chocodrink_card_meta')} <Flag name='fr' /></Card.Meta>
                        <Card.Description id={product_1_selected ? 'card-selected' : false}>
                            {product_1_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_chocodrink_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps2} id={CardSelectedOrHover(product_2_selected, isHovering2)} onClick={() => {
                    setProduct_2_selected(true)
                    setProduct_1_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Teeflower")
                }
                }>
                    <Image src="/images/tea_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_2_selected ? 'card-selected' : false}>{t('standard_tee_card_header')}</Header></Card.Header>
                        <Card.Description id={product_2_selected ? 'card-selected' : false}>
                            {t('standard_tee_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_2_selected ? 'card-selected' : false}>{t('standard_tee_card_meta')} <Flag name='cn' /></Card.Meta>
                        <Card.Description id={product_2_selected ? 'card-selected' : false}>
                            {product_2_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_tee_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps3} id={CardSelectedOrHover(product_3_selected, isHovering3)} onClick={() => {
                    setProduct_3_selected(true)
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Magnetic Cube")
                }
                }>
                    <Image src="/images/magnets_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_3_selected ? 'card-selected' : false}>{t('standard_magnet_card_header')}</Header></Card.Header>
                        <Card.Description id={product_3_selected ? 'card-selected' : false}>
                            {t('standard_magnet_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_3_selected ? 'card-selected' : false}>{t('standard_magnet_card_meta')} <Flag name='de' /></Card.Meta>
                        <Card.Description id={product_3_selected ? 'card-selected' : false}>
                            {product_3_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_magnet_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps4} id={CardSelectedOrHover(product_4_selected, isHovering4)} onClick={() => {
                    setProduct_4_selected(true)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Licorice")
                }
                }>
                    <Image src="/images/licorice_standard.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_4_selected ? 'card-selected' : false}>{t('standard_licorice_card_header')}</Header></Card.Header>
                        <Card.Description id={product_4_selected ? 'card-selected' : false}>
                            {t('standard_licorice_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_4_selected ? 'card-selected' : false}>{t('standard_licorice_card_meta')} <Flag name='dk' /><Flag name='it' /></Card.Meta>
                        <Card.Description id={product_4_selected ? 'card-selected' : false}>
                            {product_4_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('standard_licorice_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps5} id={CardSelectedOrHover(product_5_selected, isHovering5)} onClick={() => {
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(true)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Balsamico and Olive oil")
                }
                }>
                    <Image src="/images/balsamico-olive_premium.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_5_selected ? 'card-selected' : false}>{t('premium_balsamico_card_header')}</Header></Card.Header>
                        <Card.Description id={product_5_selected ? 'card-selected' : false}>
                            {t('premium_balsamico_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_5_selected ? 'card-selected' : false}>{t('premium_balsamico_card_meta')} <Flag name='gr' /><Flag name='it' /></Card.Meta>
                        <Card.Description id={product_5_selected ? 'card-selected' : false}>
                            {product_5_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_balsamico_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps6} id={CardSelectedOrHover(product_6_selected, isHovering6)} onClick={() => {
                    setProduct_2_selected(false)
                    setProduct_1_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(true)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Pen")
                }
                }>
                    <Image src="/images/parker_premium.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_6_selected ? 'card-selected' : false}>{t('premium_pen_card_header')}</Header></Card.Header>
                        <Card.Description id={product_6_selected ? 'card-selected' : false}>
                            {t('premium_pen_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_6_selected ? 'card-selected' : false}>{t('premium_pen_card_meta')} <Flag name='fr' /></Card.Meta>
                        <Card.Description id={product_6_selected ? 'card-selected' : false}>
                            {product_6_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_pen_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps7} id={CardSelectedOrHover(product_7_selected, isHovering7)} onClick={() => {
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(true)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Coffee")
                }
                }>
                    <Image src="/images/coffee_premium.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_7_selected ? 'card-selected' : false}>{t('premium_coffee_card_header')}</Header></Card.Header>
                        <Card.Description id={product_7_selected ? 'card-selected' : false}>
                            {t('premium_coffee_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_7_selected ? 'card-selected' : false}>{t('premium_coffee_card_meta')} <Flag name='pa' /></Card.Meta>
                        <Card.Description id={product_7_selected ? 'card-selected' : false}>
                            {product_7_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_coffee_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps8} id={CardSelectedOrHover(product_8_selected, isHovering8)} onClick={() => {
                    setProduct_4_selected(false)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(true)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Lindt Chocolate")
                }
                }>
                    <Image src="/images/chocolate_premium.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_8_selected ? 'card-selected' : false}>{t('premium_choco_card_header')}</Header></Card.Header>
                        <Card.Description id={product_8_selected ? 'card-selected' : false}>
                            {t('premium_choco_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_8_selected ? 'card-selected' : false}>{t('premium_choco_card_meta')} <Flag name='de' /></Card.Meta>
                        <Card.Description id={product_8_selected ? 'card-selected' : false}>
                            {product_8_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('premium_choco_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps9} id={CardSelectedOrHover(product_9_selected, isHovering9)} onClick={() => {
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(true)
                    setProduct_10_selected(false)
                    setProduct_11_selected(false)
                    setSelectedProduct("Wine")
                }
                }>
                    <Image src="/images/wine_exclusive.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_9_selected ? 'card-selected' : false}>{t('exclusive_wine_card_header')}</Header></Card.Header>
                        <Card.Description id={product_9_selected ? 'card-selected' : false}>
                            {t('exclusive_wine_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_9_selected ? 'card-selected' : false}>{t('exclusive_wine_card_meta')} <Flag name='it' /></Card.Meta>
                        <Card.Description id={product_9_selected ? 'card-selected' : false}>
                            {product_9_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('exclusive_wine_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps10} id={CardSelectedOrHover(product_10_selected, isHovering10)} onClick={() => {
                    setProduct_2_selected(false)
                    setProduct_1_selected(false)
                    setProduct_3_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(true)
                    setProduct_11_selected(false)
                    setSelectedProduct("Zipper")
                }
                }>
                    <Image src="/images/zippo_exclusive.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_10_selected ? 'card-selected' : false}>{t('exclusive_zipper_card_header')}</Header></Card.Header>
                        <Card.Description id={product_10_selected ? 'card-selected' : false}>
                            {t('exclusive_zipper_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_10_selected ? 'card-selected' : false}>{t('exclusive_zipper_card_meta')} <Flag name='us' /></Card.Meta>
                        <Card.Description id={product_10_selected ? 'card-selected' : false}>
                            {product_10_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('exclusive_zipper_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps11} id={CardSelectedOrHover(product_11_selected, isHovering11)} onClick={() => {
                    setProduct_3_selected(false)
                    setProduct_1_selected(false)
                    setProduct_2_selected(false)
                    setProduct_4_selected(false)
                    setProduct_5_selected(false)
                    setProduct_6_selected(false)
                    setProduct_7_selected(false)
                    setProduct_8_selected(false)
                    setProduct_9_selected(false)
                    setProduct_10_selected(false)
                    setProduct_11_selected(true)
                    setSelectedProduct("Knife")
                }
                }>
                    <Image src="/images/knife_exclusive.jpg" />
                    <Card.Content>
                        <Card.Header><Header id={product_11_selected ? 'card-selected' : false}>{t('exclusive_knife_card_header')}</Header></Card.Header>
                        <Card.Description id={product_11_selected ? 'card-selected' : false}>
                            {t('exclusive_knife_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={product_11_selected ? 'card-selected' : false}>{t('exclusive_knife_card_meta')} <Flag name='ch' /></Card.Meta>
                        <Card.Description id={product_11_selected ? 'card-selected' : false}>
                            {product_11_selected ?
                            <CardButton onClick={() => {
                                setProgress(progress + 1)
                                presentChosen()
                            }} text={t('continue')} />
                            :
                            <Container textAlign='justified'>
                                {t('exclusive_knife_card_description_text')}
                            </Container>}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>

            <Divider />

            <Label attached='top left' size='large' color='orange'>Exclusive</Label>

            <div className='Container_small_right'>
                <Button positive disabled={product_1_selected || product_2_selected || product_3_selected || product_4_selected || product_5_selected || product_6_selected || product_7_selected || product_8_selected || product_9_selected || product_10_selected || product_11_selected ? false : true}
                        onClick={() => {
                            setProgress(progress + 1)
                            presentChosen()
                        }}
                >
                    {t('continue')}
                </Button>
            </div>
        </div>
        </>
    )}


    // Delivery Details
    else if (progress === 1) {return (
        <>
        <div className='Container_Modal'>
            {deviceMobile ? 
            <Step.Group unstackable widths={3} size='mini'>
                <Step>
                <Icon name='gift' color='orange'/>
                </Step>
                <Step active>
                <Icon name='truck' color='orange'/>
                </Step>
                <Step disabled>
                <Icon name='info' color='orange'/>
                </Step>
            </Step.Group> : 
            <Step.Group unstackable widths={3} size='mini'>
                <Step>
                <Icon name='gift' color='orange'/>
                <Step.Content>
                    <Step.Title>1. {t('choose_present')}</Step.Title>
                </Step.Content>
                </Step>
                <Step active>
                <Icon name='truck' color='orange'/>
                <Step.Content>
                    <Step.Title>2. {t('delivery_details')}</Step.Title>
                </Step.Content>
                </Step>
                <Step disabled>
                <Icon name='info' color='orange'/>
                <Step.Content>
                    <Step.Title>3. {t('confirmation')}</Step.Title>
                </Step.Content>
                </Step>
            </Step.Group>}

            {returnCategoryLabel(category)}

            <Divider />

            <Form>
                <Form.Group>
                    <Form.Input error={showError_FirstName} width={5} required label={t('first_name')} placeholder={t('first_name')} type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <Form.Input error={showError_LastName} width={5} required label={t('last_name')} placeholder={t('last_name')} type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Input name="order[billing_address1]" width={5} error={showError_addressline1} required label={t('address_line')+ ' 1'} placeholder={t('address_line')+ ' 1'} type='text' value={addressline1} onChange={(e) => setAdressline1(e.target.value)} />
                    <Form.Input name="order[billing_address2]" width={5} label={t('address_line')+ ' 2'} placeholder={t('address_line')+ ' 2'} type='text' value={addressline2} onChange={(e) => setAdressline2(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Input name="order[billing_postcode]" error={showError_zipcode} required label={t('zip')} placeholder={t('zip')} width={2} value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                    <Form.Input name="order[billing_city]" error={showError_city} required label={t('city')} placeholder={t('city')} width={3} value={city} onChange={(e) => setCity(e.target.value)} />
                </Form.Group>
                <Form.Input required width={5} disabled label={t('country')} placeholder={t('country')} type='text' value={country} onChange={(e) => setCountry(e.target.value)} />
            </Form>
            
            {deviceMobile ?
            <Message attached floating compact warning>
                <Icon><Flag name='germany'></Flag></Icon>
                {t('only_ship_to_germany')}
            </Message>
            :
            <div className='Container_Modal_Message_mobile'>
                <Message attached floating compact warning>
                        <Icon><Flag name='germany'></Flag></Icon>
                        {t('only_ship_to_germany')}
                </Message>
            </div>
            }

            <div id='br_replacer' />

            <div className='Container_small_left'>
                <Container textAlign='left'>{t('please_note_our')} <a target="_blank" rel="noopener noreferrer" href='/data_protection'>{t('data_protection_declaration')}</a> {t('and')} <a target="_blank" rel="noopener noreferrer" href='/terms'>{t('terms')}</a>.</Container>
            </div>
            <div id='br_replacer' />

            <Form>  
                <Form.Checkbox
                    required
                    label={t('agree_terms')}
                    checked={checked}
                    onClick={() => {
                        if (checked===false) setChecked(true)
                        else if (checked===true) setChecked(false)
                    }}
                    error={showError ? errorPointingDirection() : false}
                />
            </Form>

            <Divider />

            <Form>
                <Form.Group>
                    <Form.Input error={showError_email} width={4} label='E-Mail' placeholder='E-Mail' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <Form.Input width={3} label='Phone' placeholder='Phone' type='text' value={mobil} onChange={(e) => setMobil(e.target.value)} />
                    <Form.Input width={3} label={t('birthday')} placeholder='06.09.1969' type='text' value={birthday} onChange={(e) => setBirthday(e.target.value)} />
                </Form.Group>
            </Form>

            <div id='br_replacer' />
            <div className='Container_small_left'>
                <Container textAlign='left'><Header size='tiny' color='red'>* {t('mandatory_field')}</Header></Container>
            </div>

            <Divider />

            <div className='Container_small_right'>
                <Button onClick={() => {
                    setProgress(progress - 1)
                    presentChosenReverse()
                }}>
                    {t('back')}
                </Button>
                <Button positive onClick={() => {
                    if (email!==""){
                        if (checked===false) setShowError(true)
                        if (firstName==="") setShowError_FirstName(true)
                        if (lastName==="") setShowError_LastName(true)
                        if (addressline1==="") setShowError_addressline1(true)
                        if (zipcode==="" || zipcode.length !== 5) setShowError_zipcode(true)
                        if (city==="") setShowError_city(true)
                        if (!isEmail(email)) setShowError_email(true)
                        else if (checked===true && firstName!=="" && lastName!=="" && addressline1!=="" && zipcode!=="" && zipcode.length === 5 && city!=="") {
                            setShowError(false)
                            setShowError_FirstName(false)
                            setShowError_LastName(false)
                            setShowError_addressline1(false)
                            setShowError_zipcode(false)
                            setShowError_city(false)
                            setShowError_email(false)
                            //getIP()
                            setProgress(progress + 1)
                        }
                    }
                    else {
                        if (checked===false) setShowError(true)
                        if (firstName==="") setShowError_FirstName(true)
                        if (lastName==="") setShowError_LastName(true)
                        if (addressline1==="") setShowError_addressline1(true)
                        if (zipcode==="" || zipcode.length !== 5) setShowError_zipcode(true)
                        if (city==="") setShowError_city(true)
                        else if (checked===true && firstName!=="" && lastName!=="" && addressline1!=="" && zipcode!=="" && zipcode.length === 5 && city!=="") {
                            setShowError(false)
                            setShowError_FirstName(false)
                            setShowError_LastName(false)
                            setShowError_addressline1(false)
                            setShowError_zipcode(false)
                            setShowError_city(false)
                            setProgress(progress + 1)
                        }
                    }
                }}>
                {t('continue')}
                </Button>
            </div>
        </div>
        </>
    )}

    // Confirmation with Address Line 2
    else if (progress === 2 && addressline2!=="") {return (
        <>
        <div className='Container_Modal'>
            {deviceMobile ? 
            <Step.Group unstackable widths={3} size='mini'>
                <Step>
                <Icon name='gift' color='orange'/>
                </Step>
                <Step>
                <Icon name='truck' color='orange'/>
                </Step>
                <Step active>
                <Icon name='info' color='orange'/>
                </Step>
            </Step.Group> : 
            <Step.Group unstackable widths={3} size='mini'>
                <Step>
                <Icon name='gift' color='orange'/>
                <Step.Content>
                    <Step.Title>1. {t('choose_present')}</Step.Title>
                </Step.Content>
                </Step>
                <Step>
                <Icon name='truck' color='orange'/>
                <Step.Content>
                    <Step.Title>2. {t('delivery_details')}</Step.Title>
                </Step.Content>
                </Step>
                <Step active>
                <Icon name='info' color='orange'/>
                <Step.Content>
                    <Step.Title>3. {t('confirmation')}</Step.Title>
                </Step.Content>
                </Step>
            </Step.Group>}

            {returnCategoryLabel(category)}

            <Divider />

            <Header as='h2' size='large'>
                <Header.Content>
                    {t('confirm_delivery_address')}
                    <Header.Subheader>{t('your_present')}</Header.Subheader>
                </Header.Content>
            </Header>

            <Grid stackable columns='three'>
            <Grid.Row>
                <Grid.Column width={5.33}>
                    <div className='Container_small'>
                        <SelectedPresent present={selectedProduct} />
                    </div>
                </Grid.Column>
                {deviceMobile ?
                <Grid.Column width={5.33}>
                    <div className='Container'>
                        <Icon color='orange' size='massive' name='long arrow alternate down'/>
                    </div>
                </Grid.Column> :
                <Grid.Column width={5.33}>
                    <div className='Container_Text_Center'>
                        <div id='br_replacer' />
                        <div id='br_replacer' />
                        <Icon color='orange' size='massive' name='long arrow alternate right'/>
                        {t('expected_delivery')}
                    </div>
                </Grid.Column>
                }
                <Grid.Column width={5.33}>
                    <div className='Container_small'> 
                        <Segment padded color='orange' size='massive'>
                            <Header size='tiny'>{t('shipping_address')}</Header>
                            {firstName} {lastName} <div id='br_replacer' />
                            {addressline1} <div id='br_replacer' />
                            {addressline2} <div id='br_replacer' />
                            {zipcode}   {city} <div id='br_replacer' />
                            {country}
                        </Segment>
                    </div>
                </Grid.Column>
            </Grid.Row>
            </Grid>

            <div id='br_replacer' />

            <Divider />

            <div className='Container_small_right'>
                <Button onClick={() => setProgress(progress - 1)}>{t('back')}</Button>
                <Button positive onClick={() => {
                    //saveRecipientInformation(presentCode, firstName, lastName, addressline1, addressline2, zipcode, city, country, email, mobil, birthday, clientIP)
                    //validatePresentCode(presentCode)
                    //sendEmail()
                    //setProgress(progress + 1)
                    confirmCheckPresentCode(presentCode)
                }}
                >
                    {t('confirm')}
                </Button>
            </div>
        </div>
        </>
    )}

    // Confirmation without Address Line 2
    else if (progress === 2 && addressline2==="") {return (
        <>
        <div className='Container_Modal'>
            {deviceMobile ? 
            <Step.Group unstackable widths={3} size='mini'>
                <Step>
                <Icon name='gift' color='orange'/>
                </Step>
                <Step>
                <Icon name='truck' color='orange'/>
                </Step>
                <Step active>
                <Icon name='info' color='orange'/>
                </Step>
            </Step.Group> : 
            <Step.Group unstackable widths={3} size='mini'>
                <Step>
                <Icon name='gift' color='orange'/>
                <Step.Content>
                    <Step.Title>1. {t('choose_present')}</Step.Title>
                </Step.Content>
                </Step>
                <Step>
                <Icon name='truck' color='orange'/>
                <Step.Content>
                    <Step.Title>2. {t('delivery_details')}</Step.Title>
                </Step.Content>
                </Step>
                <Step active>
                <Icon name='info' color='orange'/>
                <Step.Content>
                    <Step.Title>3. {t('confirmation')}</Step.Title>
                </Step.Content>
                </Step>
            </Step.Group>}

            {returnCategoryLabel(category)}

            <Divider />

            <Header as='h2' size='large'>
                <Header.Content>
                    {t('confirm_delivery_address')}
                    <Header.Subheader>{t('your_present')}</Header.Subheader>
                </Header.Content>
            </Header>

            <Grid stackable columns='three'>
            <Grid.Row>
                <Grid.Column width={5.33}>
                    <div className='Container_small'>
                        <SelectedPresent present={selectedProduct} />
                    </div>
                </Grid.Column>
                {deviceMobile ?
                <Grid.Column width={5.33}>
                    <div className='Container'>
                        <Icon color='orange' size='massive' name='long arrow alternate down'/>
                    </div>
                </Grid.Column> :
                <Grid.Column width={5.33}>
                    <div className='Container_Text_Center'>
                        <div id='br_replacer' />
                        <div id='br_replacer' />
                        <Icon color='orange' size='massive' name='long arrow alternate right'/>
                        {t('expected_delivery')}
                    </div>
                </Grid.Column>
                }
                <Grid.Column width={5.33}>
                    <div className='Container_small'> 
                        <Segment padded color='orange' size='massive'>
                            <Header size='tiny'>{t('shipping_address')}</Header>
                            {firstName} {lastName} <div id='br_replacer' />
                            {addressline1} <div id='br_replacer' />
                            {zipcode}   {city} <div id='br_replacer' />
                            {country}
                        </Segment>
                    </div>
                </Grid.Column>
            </Grid.Row>
            </Grid>

            <div id='br_replacer' />

            <Divider />

            <div className='Container_small_right'>
                <Button onClick={() => setProgress(progress - 1)}>{t('back')}</Button>
                <Button positive onClick={() => {
                    //saveRecipientInformation(presentCode, firstName, lastName, addressline1, addressline2, zipcode, city, country, email, mobil, birthday, clientIP)
                    //validatePresentCode(presentCode)
                    //sendEmail()
                    //setProgress(progress + 1)
                    confirmCheckPresentCode(presentCode)
                }}
                >
                    {t('confirm')}
                </Button>
            </div>
        </div>
        </>
    )}

    else if (progress === 3 && addressline2!=="") {return (
        <>
        <div className='Container_Modal'>
            <div className='Container'>
                <Header size='large'>{t('present_will_be_delivered_soon')}</Header>
                <Icon name='gift' size='massive' color='orange' />
                <div id='br_replacer' />
                {t('present_will_be_delivered_to')}:
                    <div id='br_replacer' />
                    <div id='br_replacer' />
                    {firstName} {lastName} <br />
                    {addressline1} <br />
                    {addressline2} <br />
                    {zipcode}   {city} <br />
                    {country}
            </div>

            {returnCategoryLabel(category)}
            
            <Divider />

            <div className='Container_small_middle'>
                <Button size='large' positive onClick={() => setProgress(progress + 1)}>Okay</Button>
            </div>
        </div>
        </>
    )}

    else if (progress === 3 && addressline2==="") {return (
        <>
        <div className='Container_Modal'>
            <div className='Container'>
                <Header size='large'>{t('present_will_be_delivered_soon')}</Header>
                <Icon name='gift' size='massive' color='orange' />
                <div id='br_replacer' />
                {t('present_will_be_delivered_to')}:
                    <div id='br_replacer' />
                    <div id='br_replacer' />
                    {firstName} {lastName} <br />
                    {addressline1} <br />
                    {zipcode}   {city} <br />
                    {country}
            </div>

            {returnCategoryLabel(category)}
            
            <Divider />

            <div className='Container_small_middle'>
                <Button size='large' positive onClick={() => setProgress(progress + 1)}>Okay</Button>
            </div>
        </div>
        </>
    )}

    else if (progress === 4) {return (
        <>
        <div className='Container_Modal'>
            <div className='Container'>
                <Header size='huge'>{t('make_happy')}</Header>
                <Header size='large'>{t('make_happy2')}</Header>
            </div>

            <div className='Container'>
                <Button size='huge' positive onClick={() => routeChange('/')}>{t('give_present')}</Button>
            </div>
        </div>
        </>
    )}
    else if (progress === 10) {return(
        <>
        <div className='Container_Modal'>
            <div className='Container'>
                <Icon name='exclamation circle' color='red' size='massive'/>
                <div id='br_replacer' />
                <div id='br_replacer' />
                <Header size='large'>{t('code_used')}</Header>
            </div>

            <div id='br_replacer' />

            <div className='Container'>
                <Button size='huge' onClick={() => routeChange('/')}>{t('return_to_home_page')}</Button>
            </div>
        </div>
        </>
    )}
}

export default PresentDelivery