import React, {useState, useEffect, useRef} from "react"
import { useNavigate } from "react-router-dom";
import { Button, Menu, Form, Flag, Icon, Header, Input, Divider, List, Modal, Segment, Label, Message, Image, Container } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import i18next, { use } from 'i18next'
import cookies from 'js-cookie'
import axios from "axios"
import { useParams } from "react-router-dom";

import MenuBar from "./MenuBar";
import ErrorPage from "./ErrorPage";
import BlankPage from "./BlankPage";
import PresentDelivery from "./PresentDelivery";
import ImageSliderStandard from "./Carousel_standard";
import ImageSliderPremium from "./Carousel_premium";
import ImageSliderExclusive from "./Carousel_exclusive";

import ReCAPTCHA from "react-google-recaptcha";

function exampleReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open_dimmer: true, dimmer: action.dimmer}
      case 'CLOSE_MODAL':
         return { open_dimmer: false }
      default: 
         throw new Error()
    }
}

const AutomaticCodeRedeem = () => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'

    const [deviceMobile, setDeviceMobile] = useState(false);

    const [presentCategory, setPresentCategory] = useState("")
    const [buyer_name, setBuyer_name] = useState("")
    const [recipient_name, setRecipient_name] = useState("")
    const [code_valid, setCode_valid] = useState(false);
    const [code_checked, setCode_checked] = useState(false);
    const [checkedCAPTCHA, setCheckedCAPTCHA] = useState(false);
    const [hideMessage, setHideMessage] = useState(true);

    const [delivery_address_confirmed, setDelivery_address_confirmed] = useState(false)
    const [present_chosen, setPresentChosen] = useState("fullscreen")

    const [errorMessageVisible, setErrorMessageVisible] = useState(true)

    const captchaRef = useRef(null);

    const langOptions = [
        {
          key: 'English',
          text: 'EN',
          code: 'en',
          image: <Flag name='gb' />
        },
        {
          key: 'Deutsch',
          text: 'DE',
          code: 'de',
          image: <Flag name='de' />
        }
    ]

    const [state, dispatch] = React.useReducer(exampleReducer, {
        open_dimmer: false,
        dimmer: undefined,
    })
    const { open_dimmer, dimmer } = state

    useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
        )
    ) {
        setDeviceMobile(true);
    } else {
        setDeviceMobile(false);
    }
    }, []);

    const [activeItem, setActiveItem] = useState('')

    let navigate = useNavigate(); 
    const routeChange = (x) =>{ 
      let path = `${x}`; 
      navigate(path);
    }

    const checkPresentCode = async (pk) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                setPresentCategory(res.data.Item.category)
                setBuyer_name(res.data.Item.buyer_name)
                setRecipient_name(res.data.Item.recipient_name)
                setCode_checked(true)
                setCode_valid(true)
            }
            else {
                setCode_valid(false)
                setCode_checked(true);
                console.log('This code does not exist or has already been used.');
            }
        }
        catch (err) {
            setCode_checked(true);
            setCode_valid(false)
            console.log(`An error has occurred: ${err}`);
        }
    }

    const checkHuman = () => {
        let token = captchaRef.current.getValue();
        if (token) {
            checkPresentCode(code)
            setCheckedCAPTCHA(true);
        } else {
            setHideMessage(false)
            setCheckedCAPTCHA(false)
        }
    }

    const doubleCheckPresentCode = async (pk) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })
            } else {
                setErrorMessageVisible(false)
            }
        }
        catch (err){
            setErrorMessageVisible(false)
            console.log(`An error has occurred: ${err}`);
        }
    }

    const deliveryConfirmed = () => {
        console.log("Delivery address confirmed.")
        setDelivery_address_confirmed(true)
    }
    
    const presentChosen = () => {
        console.log("Present chosen.")
        setPresentChosen("large")
    }

    const presentChosenReverse = () => {
        console.log("Present chosen.")
        setPresentChosen("fullscreen")
    }

    const showProductImages = (presentCategory) => {
        if (presentCategory === "standard"){
            if (deviceMobile)
                return(
                    <ImageSliderStandard />
                )
            else return(
                    <Image.Group size='small'>
                        <Image src="/images/chocodrink_standard.jpg" rounded />
                        <Image src="/images/tea_standard.jpg" rounded />
                        <Image src="/images/magnets_standard.jpg" rounded />
                        <Image src="/images/licorice_standard.jpg" rounded />
                    </Image.Group>
                )
        }
        if (presentCategory === "premium"){
            if (deviceMobile)
                return(
                    <>
                    <ImageSliderPremium />
                    <Icon name="plus circle" size="big" color="orange"/>
                    <Header textAlign='center' size='medium'>{t('recipient_plus_standard')}</Header>
                    </>
                )
            else return(
                    <>
                    <Image.Group size='small'>
                        <Image src="/images/balsamico-olive_premium.jpg" rounded />
                        <Image src="/images/parker_premium.jpg" rounded />
                        <Image src="/images/coffee_premium.jpg" rounded />
                        <Image src="/images/chocolate_premium.jpg" rounded />
                    </Image.Group>
                    <br />
                    <Icon name="plus circle" size="big" color="orange"/>
                    <Header textAlign='center' size='medium'>{t('recipient_plus_standard')}</Header>
                    </>
                )
        }
        if (presentCategory === "exclusive"){
            if (deviceMobile)
                return(
                    <>
                    <ImageSliderExclusive />
                    <Icon name="plus circle" size="big" color="orange"/>
                    <Header textAlign='center' size='medium'>{t('recipient_plus_standard_premium')}</Header>
                    </>
                )
            else return(
                    <>
                    <Image.Group size='small'>
                        <Image src="/images/wine_exclusive.jpg" rounded />
                        <Image src="/images/zippo_exclusive.jpg" rounded />
                        <Image src="/images/knife_exclusive.jpg" rounded />
                    </Image.Group>
                    <br />
                    <Icon name="plus circle" size="big" color="orange"/>
                    <Header textAlign='center' size='medium'>{t('recipient_plus_standard_premium')}</Header>
                    </>
                )
        }
    }

    const routeParams = useParams();
    const code = routeParams.code.slice(1, -1);

    if (checkedCAPTCHA === false){
        return (
            <>
                <div className="App">

                    <MenuBar />

                    <div className="Container">
                        <Header size='large'>{t('RECAPTCHA')}</Header>

                        <br /><br />

                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
                        
                        <br />

                        <Message hidden={hideMessage} error content={t('RECAPTCHA_error')} />

                        <br />

                        <Button circular color="orange" size="huge" onClick={() => checkHuman()}>
                            {t('verify')}
                        </Button>
                    </div>
                    
                    <br /><br /><br /><br />
                    {/*Footer*/}

                    <div className="Container_bottom">
                        <List centered bulleted horizontal link size='large'>
                            <List.Item as='a' onClick={() => navigate('/terms')}>
                            {t('terms')}
                            </List.Item>
                            <List.Item as='a' onClick={() => navigate('/data_protection')}>
                            {t('data_protection')}
                            </List.Item>
                            <List.Item as='a' onClick={() => navigate('/imprint')}>
                            {t('imprint')}
                            </List.Item>
                        </List>
                        <div className="Container_middle">
                            <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    if (checkedCAPTCHA === true && code_valid === false && code_checked === false) {return (
        <BlankPage />
    )}
    
    if (checkedCAPTCHA === true && code_valid === false && code_checked === true) {return (
        <ErrorPage />
    )}

    if (checkedCAPTCHA === true && code_valid === true && code_checked === true) {return (
        <>
        <div className="App">
            <MenuBar />

            {buyer_name === "" ?
            <Header size='large' textAlign='center'>{t('congratulations')}{recipient_name !== "" ? ", " + recipient_name :  null}! {t('congratulations_2')}{t('congratulations_3')}</Header>
            :
            <Header size='large' textAlign='center'>{t('congratulations')}{recipient_name !== "" ? ", " + recipient_name :  null}! {t('congratulations_2')} {t('from')} {buyer_name}{t('congratulations_3')}</Header>
            }
            
            <div className="Container">
                <Segment placeholder compact raised>
                    <div className="Container">
                            <Header size='medium' textAlign='center'>{t('recipient_choose_between_them')}:</Header>
                            {showProductImages(presentCategory)}
                    </div>
                </Segment>
            </div>

            <div className="Container">
                <Label basic size='large' color='orange'>{t('your_code')} <b>{code}</b></Label>
                <br />
                <Button color="orange" size="huge" onClick={() => doubleCheckPresentCode(code)}>{t('choose_present')}</Button>
                <Message
                    error
                    hidden={errorMessageVisible}
                    content={t('code_used')}
                />
            </div>

            <Modal
                closeIcon
                size={present_chosen}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                dimmer={dimmer}
                open={open_dimmer}
                onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL' })
                    setPresentChosen("fullscreen")
                    if (delivery_address_confirmed){
                      navigate('/');
                    }
                }}
            >
                <Modal.Content>
                    <PresentDelivery presentCode={code} category={presentCategory} deliveryConfirmed={deliveryConfirmed} presentChosen={presentChosen} presentChosenReverse={presentChosenReverse}/>
                </Modal.Content>
            </Modal>
        </div>

        {/*Footer*/}

        <div className="Container">
            <List centered bulleted horizontal link size='large'>
                <List.Item as='a' onClick={() => navigate('/terms')}>
                {t('terms')}
                </List.Item>
                <List.Item as='a' onClick={() => navigate('/data_protection')}>
                {t('data_protection')}
                </List.Item>
                <List.Item as='a' onClick={() => navigate('/imprint')}>
                {t('imprint')}
                </List.Item>
            </List>
            <div className="Container_middle">
                <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
            </div>
        </div>
    </>
    )}
}

export default AutomaticCodeRedeem;