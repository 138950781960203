import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Card, Image, Header, Flag, Container } from 'semantic-ui-react'
import cookies from 'js-cookie'

const SelectedPresent = ({ present }) => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'

    if (present === "Chocodrink"){
        return (
            <Card>
                <Image src="/images/chocodrink_standard.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('standard_chocodrink_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('standard_chocodrink_card_meta')} <Flag name='fr' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Teeflower") {
        return (
            <Card>
                <Image src="/images/tea_standard.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('standard_tee_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('standard_tee_card_meta')} <Flag name='cn' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Magnetic Cube") {
        return (
            <Card>
                <Image src="/images/magnets_standard.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('standard_magnet_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('standard_magnet_card_meta')} <Flag name='de' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Licorice") {
        return (
            <Card>
                <Image src="/images/licorice_standard.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('standard_licorice_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('standard_licorice_card_meta')} <Flag name='dk' /><Flag name='it' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Balsamico and Olive oil") {
        return (
            <Card>
                <Image src="/images/balsamico-olive_premium.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('premium_balsamico_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('premium_balsamico_card_meta')} <Flag name='gr' /><Flag name='it' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Pen") {
        return (
            <Card>
                <Image src="/images/parker_premium.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('premium_pen_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('premium_pen_card_meta')} <Flag name='fr' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Coffee") {
        return (
            <Card>
                <Image src="/images/coffee_premium.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('premium_coffee_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('premium_coffee_card_meta')} <Flag name='pa' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Lindt Chocolate") {
        return (
            <Card>
                <Image src="/images/chocolate_premium.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('premium_choco_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('premium_choco_card_meta')} <Flag name='de' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Wine") {
        return (
            <Card>
                <Image src="/images/wine_exclusive.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('exclusive_wine_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('exclusive_wine_card_meta')} <Flag name='it' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Zipper") {
        return (
            <Card>
                <Image src="/images/zippo_exclusive.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('exclusive_zipper_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('exclusive_zipper_card_meta')} <Flag name='us' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else if (present === "Knife") {
        return (
            <Card>
                <Image src="/images/knife_exclusive.jpg" />
                <Card.Content>
                    <Card.Header><Header>{t('exclusive_knife_card_header')}</Header></Card.Header>
                    <Card.Meta>{t('exclusive_knife_card_meta')} <Flag name='ch' /></Card.Meta>
                </Card.Content>
            </Card>
        )
    }
}

export default SelectedPresent