import React, {useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";
import { Button, Menu, Dropdown, Flag, Icon, Header, Input, Divider, List, Modal, Step, Form, Message, Image, Container } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import cookies from 'js-cookie'
import axios from "axios"
import { useParams } from "react-router-dom";

import MenuBar from "./MenuBar";

const ErrorPage = () => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'

    const [deviceMobile, setDeviceMobile] = useState(false);

    const [presentCategory, setPresentCategory] = useState("")
    const [code_valid, setCode_valid] = useState(false);

    const langOptions = [
        {
          key: 'English',
          text: 'EN',
          code: 'en',
          image: <Flag name='gb' />
        },
        {
          key: 'Deutsch',
          text: 'DE',
          code: 'de',
          image: <Flag name='de' />
        }
      ]

    useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
        )
    ) {
        setDeviceMobile(true);
    } else {
        setDeviceMobile(false);
    }
    }, []);

    const [activeItem, setActiveItem] = useState('')

    let navigate = useNavigate(); 
    const routeChange = (x) =>{ 
      let path = `${x}`; 
      navigate(path);
    }

    return(
    <>
        <div className="App">

            <MenuBar />

            <div className="Container">
                <Icon name="warning sign" color="orange" size="massive" />

                <Header size='huge' textAlign='center'>{t('page_not_exists')}</Header>
                
                <br />

                <Container>
                    <Header size='large' textAlign='center'>{t('return_to_homepage_1')}<a target="_blank" rel="noopener noreferrer" href='/'>{t('return_to_homepage_2')}</a>{t('return_to_homepage_3')}<a href= "mailto:info@presentolo.com">{t('return_to_homepage_4')}</a>{t('return_to_homepage_5')}</Header>
                </Container>
            </div>
        </div>

        <br /><br /><br /><br /><br /><br /><br /><br />

        {/*Footer*/}

        <div className="Container">
            <List centered bulleted horizontal link size='large'>
                <List.Item as='a' onClick={() => navigate('/terms')}>
                {t('terms')}
                </List.Item>
                <List.Item as='a' onClick={() => navigate('/data_protection')}>
                {t('data_protection')}
                </List.Item>
                <List.Item as='a' onClick={() => navigate('/imprint')}>
                {t('imprint')}
                </List.Item>
            </List>
            <div className="Container_middle">
                <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
            </div>
        </div>
    </>
    )
}

export default ErrorPage;